import React from "react"
import { graphql } from "gatsby"

import { Container, Sidebar } from "../components"
import Seo from "../components/seo"
import { ContentWrapper } from "../elements"
import { HeaderTitle } from "../section"

const Privacy = ({ data }) => (
  <Container>
    <Seo />
    <section className="mb-16 mx-auto px-6 max-w-5xl md:mb-24">
      <HeaderTitle title="Privacy Policy" subTitle="プライバシーポリシー" />
      <ContentWrapper>
        <div className="col-span-full lg:col-span-2">
          <div className="mb-12">
            <p className="mb-4 text-sm leading-6 md:text-base md:leading-7">
              "Mani's
              Blog"（以下「当ブログ」）は、管理者であるMani（以下「当方」）が以下のとおり個人情報保護方針を定め、個人情報を取り扱います。
            </p>
          </div>
          <div className="mb-8">
            <div className="mb-4 lg:mb-6 flex items-center">
              <img src={data.bullet.publicURL} alt="bullet point" />
              <h3 className="lg:text-lg font-bold ml-2 lg:ml-4">
                個人情報の利用目的
              </h3>
            </div>
            <p className="text-sm leading-6 md:text-base md:leading-7">
              当ブログのフォームより、お問い合わせの際にメールアドレス等の個人情報をごご入力いただく場合がございますが、これらの個人情報は、返信の際に利用させていただくものであり、目的以外での利用いたしません。
            </p>
          </div>
          <div className="mb-8">
            <div className="md-4 lg:mb-6 flex items-center">
              <img src={data.bullet.publicURL} alt="bullet point" />
              <h3 className="lg:text-lg font-bold ml-2 lg:ml-4">
                アクセス解析ツールについて
              </h3>
            </div>
            <p className="text-sm leading-6 md:text-base md:leading-7">
              当ブログでは、皆様により快適にご利用をいただくために、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しております。このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しており、このトラフィックデータは匿名で収集され、個人を特定するものではありませんのでご安心ください。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは
              <span>
                <a
                  className="text-blue-400 font-medium underline"
                  href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                >
                  こちら
                </a>
              </span>
              をご参照ください。
            </p>
          </div>
          <div className="mb-8">
            <div className="md-4 lg:mb-6 flex items-center">
              <img src={data.bullet.publicURL} alt="bullet point" />
              <h3 className="lg:text-lg font-bold ml-2 lg:ml-4">
                プライバシーポリシーの変更について
              </h3>
            </div>
            <p className="text-sm leading-6 md:text-base md:leading-7">
              当ブログは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。
            </p>
          </div>
        </div>
        <Sidebar />
      </ContentWrapper>
    </section>
  </Container>
)

export default Privacy

export const query = graphql`
  {
    bullet: file(relativePath: { eq: "bullet.svg" }) {
      publicURL
    }
  }
`
